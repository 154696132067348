.containerDynamicFilters, .dialogChangeDynamicFilters, .dialogSaveDynamicFilters, .generalScheduleVisitModal {
    .MuiFormLabel-root {
        top: 1px;
        &:not(.Mui-focused, [data-shrink = "true"]) {
            font-size: .9em;
            transform: translate(14px, 9px) scale(1);
        }
    }

    .MuiInputBase-input:not(.MuiAutocomplete-input, .MuiSelect-select) {
        height: 40px;
        font-size: .9em;
        padding: 0 14px;
        &[type="tel"] {
            padding-left: 0;
        }
    }
    .MuiInputBase-multiline {
        min-height: 40px;
        padding: 5px 14px;
        .MuiInputBase-input {
            font-size: .9em;
            padding: 0;
        }
    }
    .MuiSelect-select {
        padding: 8.5px 14px;
        font-size: .9em;
    }
    .MuiFormControlLabel-root {
        .MuiTypography-root {
            font-size: .9rem;
        }
    }
    .MuiTelInput-IconButton {
        padding: 6px;
        .MuiTelInput-FlagImg {
            width: .8em;
        }
    }
    .MuiInputAdornment-root .MuiSvgIcon-root {
        width: .8em;
        height: .8em;
    }

    .MuiAutocomplete-root {
        .MuiInputBase-root {
            min-height: 40px;
            padding: 5px 65px 5px 5px;

            .MuiInputBase-input {
                font-size: .9em;
                padding: 0 0 0 5px;
            }
        }

        .MuiChip-root {
            height: 20px;
            font-size: .8rem;

            .MuiSvgIcon-root {
                width: .65em;
                height: .65em;
            }
        }
    }
}

.generalScheduleVisitModal .MuiGrid-item {
    .MuiInputBase-root {
        background: #FFFFFF;
    }
}

#formDynamicFilters-listbox {
    font-size: .9rem;
    li, li div {
        min-height: 40px;
        padding-block: 0;
    }
}

[aria-labelledby = "simple-select-standard-label"] .MuiButtonBase-root {
    font-size: .9rem;
    min-height: 40px;
}

.corporate-portal-modal.MuiDialog-root[variant="dark"] .MuiBackdrop-root {
    background: transparent;
}

.corporate-portal-modal.MuiDialog-root[variant="dark"] .MuiDialog-paper {
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(24px);
}

.corporate-portal-modal.MuiDialog-root[variant="dark"] .MuiDialogTitle-root {
    color: #fff;
}

.corporate-portal-modal.MuiDialog-root[variant="dark"] .MuiDialogContent-root {
    color: #fff;
}

.corporate-portal-modal.MuiDialog-root[variant="dark"] .MuiIconButton-root {
    color: #fff;
}

.MuiOutlinedInput-root.Mui-disabled {
    background: rgba(243, 243, 243, 0.52);
    opacity: 1;
    -webkit-text-fill-color: inherit!important;
}

.MuiInputBase-input.Mui-disabled {
    color: rgba(0, 0, 0, 0.87) !important;
    -webkit-text-fill-color: inherit!important;
}

.MuiInputLabel-root {
    padding-right: 25px!important;
}

.MuiInputLabel-root.MuiInputLabel-shrink {
    padding-right: 0!important;
}

.chart-tooltip-root {
    max-width: 60vw;
    max-height: 90vh;
    overflow: hidden;
}

.chart-tooltip-row * {
    font-size: 12px!important;
}

.chart-pie-legend-series {
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.css-uguv5e-MuiResponsiveChart-container {
    max-width: 100%;
}

.form-input-radio--columns {
    flex-direction: row!important;
}
.form-input-radio--columns .form-input-radio__label {
    width: 49%;

    @media(max-width: 1050px) {
        width: 100%;
    }
}

.form-input-multi-checkbox--columns {
    flex-direction: row!important;
    flex-wrap: wrap;
}
.form-input-multi-checkbox--columns .form-input-multi-checkbox__item {
    width: 49%;

    @media(max-width: 1050px) {
        width: 100%;
    }
}

.simple-drop-down-menu {
    .MuiPaper-root {
        margin-top: 10px;
    }
    .MuiList-root > .MuiButtonBase-root {
        cursor: auto;
        &:hover, &.Mui-focusVisible {
            background-color: transparent;
        }
    }
}

.form-input-control-textarea, .form-input-control-textarea-max {
    width: 100%;
}
